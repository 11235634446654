import { HttpsAgent } from "agentkeepalive";
import axios from "axios";
import { envConfig } from "src/lib/client/envConfig";
import { MovieInfoQuery } from "src/lib/movieinfo/movieinfotypes";

const keepaliveAgent = new HttpsAgent({
	maxSockets: 32,
	maxFreeSockets: 10,
	timeout: 60000,
	freeSocketTimeout: 4000

});



const axiosInstance = axios.create({
	httpsAgent: keepaliveAgent,
});

const headers = {
	"content-type": "application/json",
};
/*
const graphQLClient = new GraphQLClient(process.env.NEXT_PUBLIC_FILMINFO_ENDPOINT as string, {


});
*/
export async function filminfoQuery(query: string, params?: any): Promise<MovieInfoQuery> {
	if (params?.timerLabel) {
		console.time(params!.timerLabel);
	}
	try {
		const graphqlQuery = {
			"query": query,
			"variables": params ?? {}
		};
		const x = await axiosInstance({
			url: envConfig.NEXT_PUBLIC_FILMINFO_ENDPOINT,
			method: 'post',
			headers: headers,
			data: graphqlQuery
		});
		return x.data.data as MovieInfoQuery;
		//return await graphQLClient.request<MovieInfoQuery>(query, params);
	}
	catch (e) {
		console.error("Error movieInfo", params, e);
		throw e;
	} finally {
		if (params?.timerLabel) {
			console.timeEnd(params!.timerLabel);
		}
	}
}