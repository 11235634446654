import IFilmnytt from "src/lib/types/Filmnytt";
import { groq } from "next-sanity";

const MARKDEFS = groq`
markDefs[]{
	...,
	_type == "internalLink" => {
		"slug": select(
			@.reference->slug != null => @.reference->slug.current,
			@.reference->mainVersionEDI != null => @.reference->mainVersionEDI,
			@.reference->file != null => @.reference->file.asset->,
			@.reference->_type == "person" => @.reference->name,
			null
		),
		"isStreaming": select(
        	@.reference->isStreaming == true => true,
			false
        ),
		"docType": @.reference->_type
	}
}
`;



export function createRichTextQueryFragment(fieldName: string): string {
    return groq`${fieldName}[] {
		...,
		_type == "inlineImage" => {
			"image": {...@.image, "asset": @.image.asset->},
			"imageText": @.imageText
		},
		_type == "quote" => {
			...
    	},
        _type == "inlineGallery" => {
            "gallery": {
                @.gallery->_type == "gallery" => {
                    ...@.gallery->,
                    "images": @.gallery->images[] {
                            ...@,
                            "asset": @.asset->
                    }
                },
                @.gallery->_type == "filmomtale" => {
                    ...@.gallery->,
                    "imagesV2": @.gallery->imagesV2[] {
                        ...@,
                        "asset": @.asset->
                    },
                    "imagesOverrideV2": @.gallery->imagesOverrideV2[] {
                        ...@,
                        "asset": @.asset->
                    },
                    "mainImageGalleryV2": @.gallery->mainImageGalleryV2[] {
                        ...@,
                        "asset": @.asset->
                    }
                }
            }
        },
        _type == "diceRow" => {
            "film": @.film-> {
              reviews
            }
        },
		${MARKDEFS}
	}`;
}

export const FRONTPAGE_NEWS = groq`*[_id =='frontpage_v2' ].articleList[@->_type =='filmnytt']-> {
	...,
	"imageV2": imageV2[] {...@, "asset": @.asset->}
}`;

export const STREAMING_NEWS = groq`*[_id =='streamingguide_v1' ].articleList[@->_type =='filmnytt']-> {
	...,
	"imageV2": imageV2[] {...@, "asset": @.asset->}
}`;

/**
 *
 * @param newsList the news list
 * @param parentId the current article displayed
 * @returns a list without nulls and without the current article
 */
export function filterOtherNews(newsList: IFilmnytt[] | undefined, parentId?: string): IFilmnytt[] | undefined {
    return newsList?.filter(news => !!news && news._id !== parentId);
}

export const SNIPPET_FILMOMTALE = `
    ...,
    ${createRichTextQueryFragment("bodytext")},
    ${createRichTextQueryFragment("ingress")},
    castV2,
    genres[defined(@->)]->,
    writerV2,
    directorV2,
    producerV2,
    photoV2,
    norwegianVoiceCastV2,
    distributor->,
    "mainPosterV2": select(
        (mainPosterV2 != null) => {...mainPosterV2, "asset": mainPosterV2.asset->},
        (count(postersV2) > 0) => {...postersV2[0], "asset": postersV2[0].asset->},
        null
    ),
    "mainImageV2": select(
        (mainImageV2 != null) => {...mainImageV2, "asset": mainImageV2.asset->},
        (count(imagesV2) > 0) => {...imagesV2[0], "asset": imagesV2[0].asset->},
        (count(imagesOverrideV2) > 0) => {...imagesOverrideV2[0], "asset": imagesOverrideV2[0].asset->},
        null
    ),
    mainImageGalleryV2 != null => {"mainImageGalleryV2": {...mainImageGalleryV2, "asset": mainImageGalleryV2.asset->}},
    "imagesV2": imagesV2[defined(@)] {...@, "asset": @.asset->},
    imagesOverrideV2 != null => {"imagesOverrideV2": imagesOverrideV2[defined(@)] {...@, "asset": @.asset->}},
    trailers[defined(@->)]->,
    trailersOverride[defined(@->)]->,
    otherRelations[defined(@->)]-> {
        ...,
        "imageV2": imageV2[defined(@)] {...@, "asset": @.asset->},
    }

`;