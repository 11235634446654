"use client"
import { IFilmomtale } from "src/lib/types/Filmomtale";

import GalleryPlaceholder from "src/components/imagegallery/ImageGalleryPlaceholder";

//#region [Props]
type ImageGalleryPlaceholderProps = {
    cmsDocument?: IFilmomtale | null;
};
//#endregion

//#region [Component]
export default function ImageGalleryPlaceholder({ cmsDocument }: ImageGalleryPlaceholderProps) {
    if ((cmsDocument?.imagesV2?.length ?? 0) < 2 && (cmsDocument?.imagesOverrideV2?.length ?? 0) < 2) {
        return null;
    }
    // the placeholder image should not be the same as the mainImage of the movie
    let placeholderImage = (cmsDocument?.mainImageGalleryV2 ?? cmsDocument?.imagesOverrideV2?.[0] ?? cmsDocument?.imagesV2?.[0]);
    if (placeholderImage?._id === (cmsDocument?.mainImageV2)?._id) {
        placeholderImage = (cmsDocument?.imagesOverrideV2?.[1] ?? cmsDocument?.imagesV2[1])
    }

    return <GalleryPlaceholder images={cmsDocument?.imagesOverrideV2?.length ? cmsDocument.imagesOverrideV2 : cmsDocument?.imagesV2} placeholderAspect={2 / 1} placeholderWidth={640} placeholderImage={placeholderImage} placeholderUnoptimized />;
}
//#endregion
